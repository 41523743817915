import React, { useContext, useReducer, PropsWithChildren } from 'react';
import { SnttcStateContext, type Action } from './../Common/Types';
import { reducer } from './SnttcContextReducer';


export interface SnttcContextStore {
  state: SnttcStateContext;
  dispatch?: React.Dispatch<Action>;
}

const defaultSnttcState: SnttcStateContext = { webdebug: false, authenticated: false };
const defaultSnttcContextStore: SnttcContextStore = { state: defaultSnttcState };
const mySnttcContext = React.createContext(defaultSnttcContextStore);

export const useSnttcStateContext = () => useContext(mySnttcContext);

export const SnttcStateProvider = ({ children } : PropsWithChildren ) => {
  const [state, dispatch] = useReducer(reducer, defaultSnttcState);

  return <mySnttcContext.Provider value={{ state, dispatch }} children={children} />;
};