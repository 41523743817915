export function MenuStyle(maincontentheight: string|undefined): Object  {
    const width = window.innerWidth;

    const styles = {
      bmBurgerButton: {
        position: 'fixed',
        width: '26px',
        height: '30px',
        right: '10px',
        top: '85px'
      },
      bmBurgerBars: {
        background: '#D0D1CF'
      },
      bmBurgerBarsHover: {
        background: '#a90000'
      },
      bmCrossButton: {
        height: '24px',
        width: '24px',
        right: '36px',
      },
      bmCross: {
        background: '#bdc3c7'
      },
      bmMenuWrap: {
        position: 'fixed',
        height:  (maincontentheight===undefined ? '100%' :  maincontentheight),
      },
      bmMenu: {
        background: '#eeeeee',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
        overflow: 'visible'
      },
      bmItemList: {
        color: '#ee0000',
        padding: '0.8em',
        fontweight: 'normal'
      },
      bmItem: {
        display: 'inline-block',
          width: '100%',
        fontsize: '14px'
      },
      bmOverlay: {
        background: 'rgba(0, 255, 0, 0.3)'
      }
    }

  if (width >= 400) {
    styles.bmBurgerButton.width = '36px';
    styles.bmBurgerButton.right = '20px';
  } 

  return styles;
}
 