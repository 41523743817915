import axios from 'axios';
import { authHeaderV2, softLogoutLogin }  from './Authentication';
import log from '../../Common/Utils/log';
import toast from "react-hot-toast";

const localLogOverrule = false;
const axi = axios.create({timeout: 5000});

function imitateQueryString(params:object[]): string {
  let result:string[] = [];
  for (const [key, value] of Object.entries(params)) {
    result.push(key+"=" +(value?value:"1"))
  }
  return (result.length>0? '?' + result.join('&'):'');
}

axi.interceptors.request.use(request => {
  log(localLogOverrule,"Api.ts","Request interceptor",'Request headers',request.headers.stam);
  let authlength = (request.headers  ? JSON.stringify(request.headers).length.toString() : 'undefined');
  let uri = (request.url ? request.url : 'undefined');
  let params=(request.params? imitateQueryString(request.params) : '' );
  let method=(request.method ? request.method : 'UNDEF') ;
  log(localLogOverrule,"Api.ts","Request interceptor",'Request '+ method.toUpperCase() + ' ' + uri + params + " (authentication length="+authlength+")");
  return request
})


// TODO for reponse interceptor:
// If webrequest is in debug then strip the debug info from the reponse, log it, but then return the response object with the debug info
// so that the object can be parsed normally
axi.interceptors.response.use(
  response => {
    let size="size="+(response && response.data ? JSON.stringify(response.data).length : '-1');
    let records="#records=" + (response && response.data ? +response.data.length.toString() : '-1' );
    log(localLogOverrule,"Api.ts","Response interceptor",'Response  ' + records + ', ' + size + ", record0:" + response.data[1], response);
    return response
  },
  error => {
    if (error.response.status === 401) {
      log(true,"Api.ts","Response ERROR interceptor",'Response 401 (Unauthorized) ' +error.response.status + ', ' + error.message);
      softLogoutLogin();
      toast.error("Geen geldige authenticatie, gelieve opnieuw in te loggen!");
    } else {
      log(true,"Api.ts","Response ERROR interceptor","Response " + error.response.status + ": " +error.message);
    }
    return error
  }
)

export const api = {
  get: <T>(url: string, params?: object) =>
    axi.get<T>(url, {
      headers: authHeaderV2(),
      transformResponse: (data) => JSON.parse(data),
      ...params,
    }),
  post: <T>(url: string, data: any) =>
    axi.post<T>(url, data, {
      headers: authHeaderV2(),
    }),
  patch: <T>(url: string, data: any) =>
    axi.patch<T>(url, data, {
      headers: authHeaderV2(),
    }),
  delete: <T>(url: string) =>
    axi.delete<T>(url, {
      headers: authHeaderV2(),
    }),
};