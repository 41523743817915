import React from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';
import { useLocation } from "react-router-dom";
import { log } from './../Common/Utils';
import { MenuStyle } from './../Common/Styles';
import { MainMenuComponent } from '../Common/Types';
import { useSnttcStateContext} from './SnttcContext';

const localLogOverrule= false;

const MainMenu: React.FC<MainMenuComponent>= ({
    items,
    maincontentheight
    }) => { 
    var noshow = {
        textDecoration: 'none'
    };

    var styleSelected = {
        fontFamily: 'arial, helvetica, sansSerif',
        fontWeight: 'light',
        fontSize: '26px',
        verticalAlign: '-10px',
        color: '#36A9E1'
    };
    var styleUnselected = {
        fontFamily: 'arial, helvetica, sans-serif',
        fontWeight: 'light',
        fontSize: '26px',
        verticalAlign: '-10px',
        color: '#878787'
    };
    
    const { state } = useSnttcStateContext();
    let currentLocation = useLocation();

    if (items && items.length > 0) {
        log(state.webdebug||localLogOverrule, "MainMenu.tsx", "Main", "#Menuitems: " + items.length);

        return (
            <BurgerMenu styles={MenuStyle(maincontentheight)} width={'300px'} right isOpen={false}  noOverlay >

                { items.map(({  id, label, link }) => (
                    ( currentLocation.pathname.toString() !== link) ?
                        <div key={id}>
                            <span><a href={link} style={noshow}><p style={styleUnselected} className='menu' key={id}>{label}</p></a></span>
                        </div>
                    :
                        <div key={id}>
                            <span><a href={link} style={noshow}><p style={styleSelected} className='menu' key={id} >&#x27A4; {label}</p></a></span>
                        </div>
                    ))
                }
            </BurgerMenu>
        );
    } else {
        return ( <></> );
    }
}


export default MainMenu;