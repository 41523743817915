import styled, { css } from "styled-components";

export const TableContainer = styled.div<{$containerHeight: number, $containerWidth: number}>`
    border: 0px solid yellow;
    height:  ${props => (props.$containerHeight ? props.$containerHeight + 'px' : '250px')};
    width: ${props => (props.$containerWidth ? props.$containerWidth + 'px' : '250px')};
    overflow-y: auto;
    overflow-x: hidden;        
`;

export const SearchDiv = styled.div`
    position: sticky;   
    z-index: 7;
    top: 1px;
    left: 2px;
    width: 250px;
    heigth: 12px;
`;

export const StyledTableTable = styled.table`
    position: relative;
	border: 3px solid #186882;
    margin-left: 0px;
    margin-top : -18px;
    top: -10px;
    border-radius: 5px;
    background: #D0D1CF;    
`;

export const StyledTableTableHead = styled.thead`
    border 1px solid transparant;
    position: sticky;
    top: -3px;
    background: #D0D1CF;
    z-index: 6;
`;

export const StyledTableTableBody = styled.tbody`
    border 1px solid transparant;
    z-index: 5;
`;

export const StyledTableHeaderRow = styled.tr`
    border 1em solid transparant;
`;

export const StyledTableHeaderCell = styled.th<{$sortDirection: string, $rotateheader: boolean, $headerHeight: number }>`
    border: none;
    height: ${props => (props.$headerHeight ? props.$headerHeight + 'px' : '50px')};
    vertical-align: bottom;
    text-align: left;
    border-bottom: 2px solid transparant;
    border-left: 2px solid transparant;
    border-top: 2px solid transparant;
    border-right: 2px solid transparant;
    background: rgba(255, 122, 89, 0);

    ${props => props.$rotateheader  &&  css`
        transform: rotate(270deg);
        text-align: left;
        vertical-align: middle;`
    }
    ${props => props.$sortDirection && props.$sortDirection==="asc" && css`
        border-bottom: 2px solid #186882;`
    }
    ${props => props.$sortDirection && props.$sortDirection==="desc" && css`
        border-top: 2px solid #186882;`
    }    
    ${props => props.$rotateheader  && props.$sortDirection && props.$sortDirection==="asc" &&  css`
        transform: rotate(270deg);
        vertical-align: middle;
        text-align: left;
        border-bottom: 2px solid  transparant;
        border-top: 2px solid  transparant;
        border-left: 2px solid #186882;`
    }
    ${props => props.$rotateheader  && props.$sortDirection && props.$sortDirection==="desc" &&  css`
        transform: rotate(270deg);
        vertical-align: middle;
        text-align: left;
        border-bottom: 2px solid  transparant;
        border-top: 2px solid  transparant;        
        border-right: 2px solid #186882;`
    }
`;

export const StyledTableDataRow = styled.tr`
	color: #444444;
	border: 1px solid #186882;
    font-size: 14px;
    background: #E0E1DF;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 4px 5px;
`;

export const StyledTableDataCell = styled.td<{ $width: number|undefined , $index: number, $selected:boolean }>`
    font-size: 14px;
    width: ${props => (props.$width ? props.$width + 'px' : '150px')};
    ${props => props.$index %2 === 0 && css`
        background: #D0D1CF;`
    }
    ${props => props.$selected && css`
        background:  #00afec;`
    }
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 4px 5px;
`; 