import { Suspense,  ComponentType, lazy } from 'react';
import { Wave } from "@snttc/snttcComponents";
import { useWindowDimensions } from '../Common/Utils';



export const lazyMinLoadTime = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>, minLoadTimeMs = 300) =>
  lazy(() =>Promise.all([factory(), new Promise((resolve) => setTimeout(resolve, minLoadTimeMs))]).then(([moduleExports]) => moduleExports) 
 );

export const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
);

export const Loading =() => {
    const { height, width }  = useWindowDimensions();
    return (
        <Wave myHeight={(height-8)} myWidth={(width-16)} mySize="Large" label=""></Wave>
    );
};