import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import md5 from 'md5';
import styled from "styled-components";
import toast from "react-hot-toast";
import { ActionType, type AuthenticationObject } from '../Common/Types';
import { log } from '../Common/Utils';
import { strings } from "./../Common/Consts";
import {  login, logout, set2StorageStam, set2StoragePassMd5, set2StorageVoornaam, set2StorageRememberFlag,
    getStam, getPassMd5, getVoornaam, getRememberFlag, clearStorageLocalStam, clearStorageLocalVoornaam, 
    clearStorageLocalPassMd5, clearStorageLocalRememberFlag } from "../Common/Api";
import  { Input, Label, Button, Checkbox, Wave } from "@snttc/snttcComponents" ;
import { useSnttcStateContext} from './SnttcContext';

const localLogOverrule = false;

const InputDiv= styled.div`
    justify-self: end;
    border: 0px solid red;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    min-width: 120px;
    max-width: 300px;
    margin-top: 6px;
    margin-right: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    height: 40px;
    @media (min-width: 460px) {
        height: 25px;
    }    
`;

const ButtonDiv = styled.div`
    justify-self: end;
    border: 0px solid red;
    display: grid;
    min-width: 120px;
    max-width: 160px;
    margin-top: 4px;
    margin-right: 7px;
    margin-bottom: 2px;
    margin-left: 18px;
    height: 45px;
`;

const AuthenticationDiv = styled.div`
    border: 0px solid green;  
    min-width: 120px;
    max-width: 450px;
    height: 120px;
    text-align: center;
    color: #D0D1CF;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    align-content: right;
    @media (min-width: 768px) {
        grid-template-columns: 70% 30%;
    }
`;

const RememberMeDiv = styled.div`
    border: 0px solid green;  
    text-align: right;
    margin-top: 6px;
    margin-right: 4px;
    width: 120px;
    font-size: 12px;
    font-family: tahoma, verdana, arial, helvetica, sans-serif;
    font-weight: normal;
    color: #D0D1CF;
`;

const Authentication: React.FC = () => {
    let navigate = useNavigate();
    const { state, dispatch } = useSnttcStateContext();        
	const [stam, setStam] = useState(getStam() && getStam().length>0 ? getStam() : "");
    const [md5password, setmd5Password] = useState(getPassMd5() && getPassMd5().length>0 ? getPassMd5() : "");
    // voornaam is ook de flag of we ingelogd zijn of niet in storage
    // als de voornaam in storage gekend is zijn we dus ingelogd, anders niet
    const [voornaam, setVoornaam] = useState(getVoornaam() && getVoornaam().length>0 ? getVoornaam() : "");
    const [rememberMe, setRememberMe] = useState(getRememberFlag());
    const [loading,setLoading] = useState(false);
    const [password, setPassword] = useState("");
    
    useEffect(() => {
        if (state.authenticated ===false && stam.length>0 && md5password.length>0 && voornaam.length>0 )  {
            // Ik heb alles (wss via rememberme) dus eigenlijk ben ik al geauthenticeerd
            if (dispatch) { dispatch({ type :  ActionType.AUTHENTICATED}); }
        } 
    });

    log(state.webdebug||localLogOverrule, "Authentication.tsx", "main","Stam: " + stam + ", md5=" + md5password + ", voornaam:" + voornaam+ ", authenticated: " + state.authenticated);
    log(state.webdebug||localLogOverrule, "Authentication.tsx", "main","rememberMe: " + rememberMe + ", loading=" + loading + ", passwordlength:" + password.length );

    const handleOnchangeRememberMe = () => {
        let newVal = !rememberMe ;
        setRememberMe(newVal);
        set2StorageRememberFlag(newVal);
    }

    const doSetUserName = (username: string) => {
        setStam(username);
        set2StorageStam(username);
    }

    const doSetPassword = (password: string) => {
        setPassword(password);
        setmd5Password(md5(password));
        set2StoragePassMd5(md5(password));
    }

    useEffect(() => {
        if (rememberMe) {
            set2StorageStam(stam);
            set2StoragePassMd5(md5password);
            set2StorageVoornaam(voornaam);
        } else {
            clearStorageLocalStam();
            clearStorageLocalPassMd5();
            clearStorageLocalVoornaam();
        }
    }, [rememberMe, stam, password, md5password, voornaam]);

    const handleLogin = (username: string, md5password: string ) => {
        if (username && md5password && username.length > 0 && md5password.length > 0) {
            setLoading(true);
            login(username, md5password).then(
                (data) => {
                    let user: AuthenticationObject = JSON.parse(data);     
                    if (dispatch && state.authenticated === false &&  user.jwt.length>10 ) { 
                        set2StorageRememberFlag(rememberMe);
                        set2StorageVoornaam(user.v_naam);
                        setVoornaam(user.v_naam);
                        set2StorageStam(stam);
                        set2StoragePassMd5(md5password);
                        dispatch({ type :  ActionType.AUTHENTICATED}); 
                    }       
                    setLoading(false);    
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    log(state.webdebug||localLogOverrule,"Authentication.tsx","handleLogin","Failure: " + resMessage,  error.response ); 
                    toast.error("Login: "+ strings.app.login.failureMessage);
                    setLoading(false);
                }
            )
        } else {
            toast.error("Login: "+ strings.app.login.failureMessageEmpty);
        }
    };

    const handleLogout = () => {
        logout();
        setmd5Password("");
        setPassword("");
        setVoornaam("");
        // Logout also has to clear the rememeber flag, there is no good reason to logout if you want to be remembered
        setRememberMe(false);
        clearStorageLocalRememberFlag();
        if (dispatch && state.authenticated === true ) { dispatch({ type :  ActionType.NOTAUTHENTICATED }); }
        navigate("/", {replace: true});
    }

    return (
        <>
            <AuthenticationDiv>
                <InputDiv>
                        { ! state.authenticated ? 
                            <Input myDefaultValue={stam} myId="username-input" myLabel="" mySize="Small" placeholder="gebruiker"  myLabelwidth={5} myWidth={140} validation="required|minLength-3"  onChange={(event: React.ChangeEvent<HTMLInputElement>) => doSetUserName(event.target.value)} /> 
                            : 
                            <Label htmlFor="dummy" mySize="small" myLabelwidth={145}  myLabel=" " /> }
            
                        { ! state.authenticated ? 
                            <Input myDefaultValue={password} myId="username-password"  myLabel="" mySize="Small" placeholder="paswoord" myLabelwidth={5} myWidth={140} type="password" validation="required" onChange={(event: React.ChangeEvent<HTMLInputElement>) => doSetPassword(event.target.value)} /> 
                            : 
                            <Label htmlFor="gebruiker" mySize="small" myLabelwidth={245} myWidth={170} myPosition="RightRight" myLabel={" Welkom "+voornaam+" "}  /> }
                </InputDiv>    
                <ButtonDiv className="ButtonDiv">
                    {  ! state.authenticated ? 
                        <Button myId ="authButton"  mySize="Small" myLoadingText="Logging in..." myDisplayText="Login" onClick={() => handleLogin(stam, md5password)} myWidth={116} /> 
                        :
                        <Button myId ="logoutButton" mySize="Small" myLoadingText="Logging out..." myDisplayText="Logout" onClick={() => handleLogout() } myWidth={116} /> }
                    { (loading ) ? <Wave size="S" label="" />  : ' ' }    
                    <RememberMeDiv key={"remember_" + rememberMe.toString()}>                        
                        <Checkbox mySize="small" myLabel={strings.app.login.rememberme} myDefaultValue={rememberMe} myLabelwidth={80} myWidth={120}  onChange={() => handleOnchangeRememberMe()} />
                    </RememberMeDiv>
                </ButtonDiv>
            </AuthenticationDiv>
        </>
    )
};
  
export default Authentication

