import axios from "axios";
import { strings } from '../Consts/strings.constants'
import { log } from '../../Common/Utils';

const localLogOverrule = true;

export const login = (stam: string, password: string)   => {
  return axios
    .post(process.env.REACT_APP_API_URL + '/login/doLogin', {
      'stam': stam,
      'paswoord': password,
    })
    .then((response) => {

      if (response.data.jwt) {
        set2StorageVoornaam(JSON.stringify(response.data.jwt.v_naam));
      }
  
      return JSON.stringify(response.data);
    });
};
  
export function authHeaderV2() {
  let stam: string = getStam();
  if (stam &&stam.length>0) {
    let passmd5: string =  getPassMd5();
    if( passmd5 && passmd5.length>0) {
      return {
        'stam': stam,
        'paswoord': passmd5
      }
    }
  }
  return { 'stam': '', 'paswoord' : ''} ;
}

export const softLogoutLogin = () =>{
  log(localLogOverrule,"Authentication.ts","softLogoutLogin", "Token expired?  Going to renew!"); 
  if ( getStam().length>0 && getPassMd5().length > 0 ) {
    login(getStam(), getPassMd5()) ;
  }
}

export const logout = () => {
  localStorage.removeItem(strings.app.login.localstorageVoornaam);
  localStorage.removeItem(strings.app.login.localstoragePass);
  localStorage.removeItem(strings.app.login.localstorageRememberMe);
  localStorage.removeItem(strings.app.login.localstorageStam);
  sessionStorage.removeItem(strings.app.login.localstorageVoornaam);
  sessionStorage.removeItem(strings.app.login.localstoragePass);
  sessionStorage.removeItem(strings.app.login.localstorageRememberMe);
  sessionStorage.removeItem(strings.app.login.localstorageStam);
};
  
// SET + GET stam to localstorage
export const set2StorageStam = (stam: string) => {
  sessionStorage.setItem(strings.app.login.localstorageStam, stam);
  if  (getRememberFlag()) localStorage.setItem(strings.app.login.localstorageStam, stam);
}
export const getStam = () => {
  const stamLocalStr = localStorage.getItem(strings.app.login.localstorageStam);
  if (stamLocalStr) return stamLocalStr ;
  const stamSessStr = sessionStorage.getItem(strings.app.login.localstorageStam);
  if (stamSessStr) return stamSessStr ;
  return "";
}
export const clearStorageLocalStam=() => {
  localStorage.removeItem(strings.app.login.localstorageStam);
}

// SET + GET voornaam to localstorage
export const set2StorageVoornaam = (voornaam: string) => {
  sessionStorage.setItem(strings.app.login.localstorageVoornaam, voornaam);
  if (getRememberFlag())  localStorage.setItem(strings.app.login.localstorageVoornaam, voornaam);
}
export const getVoornaam = () => {
  const voornaamLocalStr = localStorage.getItem(strings.app.login.localstorageVoornaam);
  if (voornaamLocalStr && voornaamLocalStr.length>0 ) return voornaamLocalStr ;
  const voornaamSessStr = sessionStorage.getItem(strings.app.login.localstorageVoornaam);
  if (voornaamSessStr) return voornaamSessStr ;
  return "";
}
export const clearStorageLocalVoornaam =() => {
  localStorage.removeItem(strings.app.login.localstorageVoornaam);
}
export const isAuthenticated = () => {
  return (getVoornaam().length>0? true:false) && (getStam().length>0? true:false) && (getPassMd5().length>0? true:false);
}

// SET + GET md5 hash password to localstorage
export const set2StoragePassMd5 = (pass: string) => {
  sessionStorage.setItem(strings.app.login.localstoragePass, pass);
  if (getRememberFlag()) localStorage.setItem(strings.app.login.localstoragePass,pass);
}
export const getPassMd5 = () => {
  const passLocalStr = localStorage.getItem(strings.app.login.localstoragePass);
  if (passLocalStr && passLocalStr.length>0 ) return passLocalStr ;
  const passSessStr = sessionStorage.getItem(strings.app.login.localstoragePass);
  if (passSessStr && passSessStr.length>0 )  return passSessStr ;
  return "";
}
export const clearStorageLocalPassMd5 =() => {
  localStorage.removeItem(strings.app.login.localstoragePass);
}

//SET + GET rememberflag to localstorage
export const set2StorageRememberFlag = (remember: boolean) => {
  if (remember) localStorage.setItem(strings.app.login.localstorageRememberMe, remember.toString()) ;
  else  { clearStorageLocalRememberFlag() ; }
}
export const getRememberFlag = () => {
  const rememberStr = localStorage.getItem(strings.app.login.localstorageRememberMe); 
  if (rememberStr && rememberStr==="true") return true;
  return false;
}
export const clearStorageLocalRememberFlag =() => {
  localStorage.removeItem(strings.app.login.localstorageRememberMe);
}