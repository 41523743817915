import React, { FC } from "react";
import { Loadable, lazyMinLoadTime } from '../App/Loadable';
import { PathRouteProps } from "react-router-dom";

type PathRouteCustomProps = {
    title?: string
    navigator:string
    exact?: boolean
    pagelocation: string
    component: FC
};

export type myRoute = PathRouteProps & PathRouteCustomProps

export const PagesConfigs: myRoute[] = [
    {
        path: "/",
        title: 'Welcome',
        navigator: '/Home',
        component: Loadable(lazyMinLoadTime(() => import('./SponsorPage/SponsorPage') )),
        pagelocation: "/"
    },
    {
        path: "/beheerleden",
        exact: false,
        navigator: "Leden",
        component: Loadable(lazyMinLoadTime(() => import('./Beheerleden/Views/Beheerleden') )),
        pagelocation: "/Pages/Beheerleden"
    },
    {
        path: "/beheerleden/:id",
        exact: false,
        navigator: "Leden",
        component: Loadable(lazyMinLoadTime(() => import('./Beheerleden/Views/Beheerleden') )),
        pagelocation: "/Pages/Beheerleden"
    }, 
    {
        path: "/rollen",
        exact: false,
        navigator: "Rollen",
        component: Loadable(lazyMinLoadTime(() => import('./Beheerleden/Views/Rollen') )),
        pagelocation: "Pages/Beheerleden"
    },     
    {
        path: "/rollen/clubgegevens",
        exact: false,
        navigator: "Rollen",
        component: Loadable(lazyMinLoadTime(() => import('./Beheerleden/Views/Rollen') )),
        pagelocation: "Pages/Beheerleden"
    },     
    {
        path: "/rollen/inschrijvingen",
        exact: false,
        navigator: "Rollen",
        component: Loadable(lazyMinLoadTime(() => import('./Beheerleden/Views/Rollen') )),
        pagelocation: "Pages/Beheerleden"
    }, 
    {
        path: "/rollen/machtigingen",
        exact: false,
        navigator: "Rollen",
        component: Loadable(lazyMinLoadTime(() => import('./Beheerleden/Views/Rollen') )),
        pagelocation: "Pages/Beheerleden"
    },     
    {
        path: "/instellingen",
        exact: false,
        navigator: "Instellingen",
        component: Loadable(lazyMinLoadTime(() => import('./Instellingen/Views/Instellingen') )),
        pagelocation: "/Pages/Instellingen"
    },
    {
        path: "/betalingen",
        exact: false,
        navigator: "Betalingen",
        component: Loadable(lazyMinLoadTime(() => import('./Betalingen/Views/Betalingen'))),
        pagelocation: "/Pages/Betalingen"
    },          
    {
        path: "/betalingen/betalingen",
        exact: false,
        navigator: "Betalingen",
        component: Loadable(lazyMinLoadTime(() => import('./Betalingen/Views/Betalingen'))),
        pagelocation: "/Pages/Betalingen"
    },       
    {
        path: "/betalingen/openstaande",
        exact: false,
        navigator: "Betalingen",
        component: Loadable(lazyMinLoadTime(() => import('./Betalingen/Views/Betalingen'))),
        pagelocation: "/Pages/Betalingen"
    },  
    {
        path: "/betalingen/afwijkende",
        exact: false,
        navigator: "Betalingen",
        component: Loadable(lazyMinLoadTime(() => import('./Betalingen/Views/Betalingen'))),
        pagelocation: "/Pages/Betalingen"
    },            
    {
        path: "/super-admin",
        exact: false,
        navigator: 'Frenoy',
        component: Loadable(lazyMinLoadTime(() => import('./Superadmin/HomePage') )),
        pagelocation: "Pages/Superadmin"
    },
    {
        path: "*",
        exact: false,
        navigator:'*',
        component: Loadable(lazyMinLoadTime(() => import('./SponsorPage/SponsorPage') )),
        pagelocation: "/Pages/SponsorPage"
    }   
];
       
 
///component: Loadable(React.lazy(() => import('./Betalingen/Views/Betalingen') )),