import styled from "styled-components";
import { strings } from './../Common/Consts';

const backgroundImage = process.env.REACT_APP_IMAGES_URL + "/" + process.env.REACT_APP_SITE + '/back_footer.png';
const footerHeight= (process.env.REACT_APP_FOOTER_HEIGHT ? process.env.REACT_APP_FOOTER_HEIGHT : '30px');

const StyledFooter = styled.div<{ $height: string }>`
    position: absolute;
    left: 0 ; right: 0; bottom: 0;
    height:  ${props => (props.$height ? props.$height : '30px')};
	background-image: url(${backgroundImage});
	background-repeat: repeat-x;
`;

const StyledFooterCopyright = styled.div`
	margin-top: 7px;
	text-align: center;
	font-size: 12px;
	font-family: tahoma, verdana, arial, helvetica, sans-serif;
	font-weight: normal;
	color: #D0D1CF ;
`;

const Footer =() => {
	
  return (
	<StyledFooter $height={ footerHeight }>
			<StyledFooterCopyright>{'\u00A9'}{strings.app.footer.copyright}</StyledFooterCopyright>
	</StyledFooter>
  )
};

export default Footer