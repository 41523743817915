export function isset (value: any) {
    if (!arguments.length) throw new TypeError('isset requires at least one accessor function')
    return Array.prototype.slice.call(arguments).every((accessor) => {
      if (typeof accessor !== 'function') throw new TypeError('isset requires accessors to be functions')
      try {
        const value = accessor()
        return value !== undefined && value !== null
      } catch (e) {
        return false
      }
    })
}

export function IsNotNull (value: any) {
    if (typeof value !== 'undefined') {
        if (value!==null) {
            return true;
        }
    }
    return false;
}

export function hashCode (message: string) {
    var hash = 0, i = 0, len = message.length;
    while ( i < len ) {
        hash  = ((hash << 5) - hash + message.charCodeAt(i++)) << 0;
    }
    return hash;
};

export function isLocalHost(hostname = window.location.hostname) {
    return ['localhost', '127.0.0.1', '', '::1'].includes(hostname)
}

export function spacer(number: number) {
    let result=""
    for (let i = 0; i < number; i++) {
        result= result + "\u00a0";
    }
    return result;
}
/*

export function debounce(func, time: number) {
    let timeout: Timeout 

    return function (...args) {
        const ref = this

        clearTimeout(timeout)
        timeout = setTimeout(() => func.apply(ref, args), time)
    }
}





export function ConvertDate(direction, dateString) {
    if (direction === 'DB2DISPLAY') {
        return date2DisplayString(db2Date(dateString));
    } else if (direction === 'DISPLAY2DB') {
        return date2DbString(display2Date(dateString));
    }
}



export function ConvertString2Date(datestring, format=null) {
    try {
        var parts ;
        if (format===null)  {
            parts = datestring.split("-")
            return new Date(parts[0], parts[1] - 1, parts[2])        
        } if (format ==="%d-%m-%Y") {
            if (datestring==="00-00-0000") { return null; }
            parts = datestring.split("-")
            return new Date(parts[2], parts[1] - 1, parts[0])
        }
        return new Date(datestring);
    }
    catch (error) {
        return null
    }
}




function date2DbString(date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
}
function date2DisplayString(date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    return dd + '-' + mm + '-' + yyyy;
}
function db2Date(dateStr) {
    var parts = dateStr.split("-")
    return new Date(parts[0], parts[1] - 1, parts[2])
}
function display2Date(dateStr) {
    var parts = dateStr.split("-")
    return new Date(parts[2], parts[1] - 1, parts[0])
}







export const SnttcLink = (props) => (
    props.target && props.target==="_self" ?
        <>
            <a className={props.className} href={props.page} target="_self">{props.linktext}</a>
        </>
        :
        <>
            <a className={props.className} href={props.page} target={props.target} rel="noreferrer" >{props.linktext}</a> 
        </>
);

export function myKey(prelude="PRE", prefix, fix, suffix, index=0 ) {
    return (prelude+"-"+prefix+"-"+fix+"-"+suffix+"-"+index.toString());
}

*/