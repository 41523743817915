export type LogData = {
    logOverrule?: boolean | null,
    chapter: string,
    subchapter? :string,
    message?: string, 
    myobject? : any    
}

export default function log(
    logOverrule : boolean | undefined, 
    chapter: string | "", 
    subchapter: string | "",
    message: string | "",
    myobject?: any | undefined
  ): void {
    let objectstring = null;
    let prefix = "";
    let doLog: boolean | null = null ;
    if (logOverrule === undefined) {
        doLog = ( process.env.REACT_APP_DEBUG === "true") ? true : false
    } else {
        doLog = logOverrule
    }

    prefix = "(" + chapter + ")" + ((subchapter !== "") ? "(" + subchapter + ") " : " ");

    if (doLog) {
        if (myobject !== null && myobject!==undefined) {
            if (typeof myobject === 'function') {
                objectstring = myobject.toString();
            } else {
                objectstring = JSON.stringify(myobject, getCircularReplacer(), 2);
            }
        }
        console.log(prefix + message + ((objectstring !== null) ? ": " + objectstring : ""));
    }
}

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
}