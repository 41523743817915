import React, { JSXElementConstructor, useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { ActionType } from './../Common/Types';
import type { RouterComponent } from "../Common/Types";
import { log, RouterUtils } from '../Common/Utils';
import { PagesConfigs } from "../Pages/PagesConfigs";
import MainMenu from "./MainMenu";
import { useSnttcStateContext} from './SnttcContext';
import { strings } from '../Common/Consts';

const localLogOverrule= false;

// https://codesandbox.io/p/sandbox/react-authorization-by-mohammad-oftadeh-i101m?file=%2Fsrc%2Fapp-configs%2FroutesConfig.js%3A11%2C1-17%2C3
// https://github.com/suren-atoyan/react-pwa/blob/master/src/routes/index.ts

const StyledContentContainerDiv = styled.div` 
  border: 0px solid purple;
  display: flex;
  justify-content: center;
`;      

const renderComponent = (Elem: JSXElementConstructor<any>) => {
  return <><Elem /></>;
}

const SnttcRouter : React.FC<RouterComponent> = ({
  maincontentheight
  }) => {
    const { state, dispatch } = useSnttcStateContext();
    let dbMenuitems = JSON.parse(sessionStorage.getItem(strings.app.menu.sessionStorageKey) || '[]');
    let currentLocation = useLocation();

    useEffect(() => {
      if (dispatch) {
        // Only Change state if there a change otherwise its an endless loop
        if ( currentLocation.search.includes("debug") && state.webdebug === false ) { dispatch({ type :  ActionType.DEBUG_ON }); }
        if ( ! currentLocation.search.includes("debug") && state.webdebug ===true ) { dispatch({ type :  ActionType.DEBUG_OFF }); }
      }
    }, [currentLocation, state, dispatch]);

    const routes = [
      ...RouterUtils.generateRoutesFromConfigs(PagesConfigs, dbMenuitems, state.webdebug)
    ];
    const menuItems= [
      ...RouterUtils.generateMenuFromConfigs(PagesConfigs, dbMenuitems, state.webdebug)
    ]

    log(state.webdebug||localLogOverrule, "SnttcRouter.tsx", "SnttcRouter", "#routes="+routes.length+ ", #menuitems=" + menuItems.length + ", #dbmenuitems=" + dbMenuitems?.length + ", maincontentheight="+maincontentheight+ ", webdebug=" + state.webdebug);

    if (routes.length>0||menuItems.length>0) {
      return (
        <>
          <StyledContentContainerDiv id="RouterContent" className="RoutedContentContainer" >
            <MainMenu items={menuItems} maincontentheight={maincontentheight} />
              <Routes location={currentLocation} key={currentLocation.pathname} >
                {routes.map(({ path, Component: MyComponent}) => {
                  if (MyComponent!==undefined && MyComponent!==null) {
                    return <Route key={path} path={path} element={renderComponent(MyComponent)} />;
                  }
                  return null;
                })}          
              </Routes>
          </StyledContentContainerDiv>
        </>
      );
    } else {
      return (<></>);
    }
};

export default SnttcRouter;