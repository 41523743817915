export type dbLid = {
    id: string,
    f_naam: string,
    v_naam: string
    sex: string,
    geboren: string,
    straat: string,
    huis_nr: string,
    bus: string,
    post: string,
    plaats: string,
    land: string,
    tel?: string,
    gsm?: string,
    email?: string,
    paswoord: string,
    gsm_ouder1?: string,
    gsm_ouder2?: string,
    email_ouder1?: string,
    email_ouder2?: string,
    inschrijvingen: string,
    clubgegevens: string,
    actief: string,
    updated: string,
    opmerking: string,
    rijksregisternummer: string,
    lastUpdate: string,
    lastUpdateBy: string,
    toegang: string
}

export type Machtiging = {
    toegang: string
}

export type Inschrijving = {
    inschrijving: string
}

export type ClubGegeven = {
    clubgegeven: string
}                                    

export const emptyLid: dbLid = {    id: '', f_naam: '', v_naam: '', sex: '', geboren: '', rijksregisternummer: '', 
                                    straat: '', huis_nr: '', bus: '', post: '', plaats:'', land: '',  paswoord:'', 
                                    inschrijvingen: '', clubgegevens: '', actief:'',opmerking: '', updated: '', lastUpdate: '', lastUpdateBy: '', toegang: '' };


export type InschrData = {
    [key: string]: string|number
}
