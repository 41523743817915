import { createGlobalStyle } from "styled-components";


export const Global = createGlobalStyle`
  * {
  }
  .AnimatedTransition {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: black;
  }
`;

export default Global;